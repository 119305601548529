import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/usuarios${queryUrl}`)
  },
  insertNewUsers(data) {
    return axios.post('/usuarios/', data)
  },
  editUsers(data) {
    return axios.put(`/usuarios/${data.id}`, data)
  },
  deleteUsers(id) {
    return axios.put(`/usuarios/${id}`)
  },
  loadPermission() {
    return axios.get('/core/roles?page=0&size=100&sort=authority')
  },
  getById(id) {
    return axios.get(`/usuarios/${id}`)
  },
  resetPassword(data) {
    return axios.post(`/usuarios/altera_senha/`, data)
  },
  insertAssinatura(data) {
    if (!data.assinatura) {
      return new Promise((resolve) => {
        resolve({ response: { data: null } })
      })
    }
    // Criar um objeto FormData
    const formData = new FormData()
    // Adicionar o arquivo ao FormData (certifique-se de que "File" está correto)
    formData.append('file', data.assinatura)

    // Enviar o FormData utilizando Axios
    return axios.post(`/usuarios/assinatura/${data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Axios gerencia isso automaticamente, mas é bom explicitar
      }
    })
  }
}
