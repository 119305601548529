import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryFilter.order = 'asc'
    queryUrl = `?page=${queryFilter.page - 1}&size=${
      queryFilter.size
    }&sort=diaInicial,desc`
    if (queryFilter.filter) {
      queryUrl = `/pesquisar?${queryFilter.filter}`
    }
    return axios.get(`/atendimento/planejamentos${queryUrl}`)
  },
  insert(data) {
    return axios.post('/atendimento/planejamentos', data)
  },
  edit(data) {
    return axios.put(`/atendimento/planejamentos/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/atendimento/planejamentos/${id}`)
  },
  getById(id) {
    return axios.get(`/atendimento/planejamentos/${id}`)
  },
  getProfissional(id) {
    return axios.get(`/atendimento/planejamentos/${id}/profissionais`)
  },
  searchProfessional(name) {
    const endpoint = `/atendimento/agendas/pesquisar_profissional?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
  agendasById(id) {
    return axios.get(`/atendimento/planejamentos/agenda/${id}`)
  },
  bloquearDataById(id) {
    return axios.get(`/atendimento/planejamentos/bloquear_data/${id}`)
  },
  desbloquearDataById(id) {
    return axios.get(`/atendimento/planejamentos/bloquear_data/${id}`)
  },
  carregarAtendimentos(data) {
    return axios.get(
      `atendimento/atendimentos/${data.id}/${data.data}/agendamento`
    )
  },
  editAgenda(data) {
    return axios.put('/atendimento/planejamentos/alterar_data', data)
  },
  controleVagasEspecialidade(data) {
    return axios.post(`/atendimento/controles/${data.agendamento}`, data)
  },
  getControleQtdVagas(id) {
    return axios.get(`/atendimento/controles/agendamento/${id}`)
  },
  gerarEmLote() {
    return axios.get('/atendimento/planejamentos/gerar')
  }
}
