export default {
  LOAD_LIST(state, data) {
    console.log(data)
    state.listaAgendamentosEmLote = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.agendamentoLote = data
  },
  RESET(state) {
    state.agendamentoLote = {
      dataInicial: '',
      dataFinal: '',
      horaInicial: '',
      horaFinal: '',
      profissional: '',
      ativo: true,
      qtoVagas: 0,
      repetir: '',
      domingo: false,
      segunda: true,
      terca: true,
      quarta: true,
      quinta: true,
      sexta: true,
      sabado: false,
      semana: 0,
      ativo: true
    }
  }
}
