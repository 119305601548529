import userService from '@/api/access/userServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      userService
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_USERS', result.data.content)
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    )
  },
  insertNewUser({ commit }, data) {
    return userService
      .insertNewUsers(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditUser({ commit }, data) {
    commit('PREPARE_TO_EDIT_USER', data)
  },
  editUser({ commit }, data) {
    return userService
      .editUsers(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetUser({ commit }) {
    commit('RESET_USER')
  },
  loadPermission({ commit }) {
    return new Promise((resolve, reject) =>
      userService
        .loadPermission()
        .then((result) => {
          commit('SET_SYSTEM_PERMISSION', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  getById({ commit }, id) {
    return new Promise((resolve, reject) =>
      userService
        .getById(id)
        .then((result) => {
          commit('PREPARE_TO_EDIT_USER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) =>
      userService
        .resetPassword(data)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insertAssinatura({ commit }, data) {
    return new Promise((resolve, reject) =>
      userService
        .insertAssinatura(data)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  }
}
