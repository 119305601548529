import axios from '@axios'
import apiIbge from '@/api/localidades/apiIbge'

export default {
  loadComboGenero() {
    return axios.get('/core/sexos/lista')
  },
  loadComboEstadoCivil() {
    return axios.get('/core/estatos_civis/lista')
  },
  loadComboProfissoes() {
    return axios.get('/core/profissoes/lista')
  },
  loadComboNaturalidades() {
    return axios.get('/core/naturalidades/lista')
  },
  loadComboNacionalidades() {
    return axios.get('/core/nacionalidades/lista')
  },
  loadComboUfs() {
    return axios.get('/core/ufs/lista')
  },
  loadComboPrevidenciaSocial() {
    return axios.get('/core/previdencias_sociais/lista')
  },
  loadComboTipoEndereco() {
    return axios.get('/core/tipo_enderecos/lista')
  },
  loadComboSituacaoRegistro() {
    return axios.get('/core/situacoes_registros/lista')
  },
  loadComboNaturezaFiscal() {
    return axios.get('/core/naturezas_fiscais/lista')
  },
  loadComboFormaPagamento() {
    return axios.get('financeiro/forma_pagamentos_recebimentos/lista')
  },
  loadComboTipoPagamento() {
    return axios.get('/financeiro/tipo_pagamento_recebimentos/lista')
  },
  loadComboFaixaContribuicao() {
    return axios.get('/financeiro/faixa_contribuicoes/lista')
  },
  loadComboDepedenteAssociadoTipo(id) {
    return axios.get(`/administrativo/associados/dependentes/${id}/Agregados`)
  },
  loadComboTaxas() {
    return axios.get('/financeiro/taxas/lista')
  },
  loadComboTipoAtendimento() {
    return axios.get('/atendimento/tipo_atendimentos/lista')
  },
  loadComboEspecialidades() {
    return axios.get('/atendimento/especialidades/lista')
  },
  loadComboTipoProtocolo() {
    return axios.get('/core/tipos_protocolos/lista')
  },
  loadComboMeioProtocolo() {
    return axios.get('/protocolo/protocolo_meios/lista')
  },
  loadComboTypesRegister(id) {
    return axios.get(`/core/tipos_registros/tipo/${id}`)
  },
  loadComboUnidadeAtendimento() {
    return axios.get('/atendimento/unidade_atendimentos/lista')
  },
  loadComboConvenio() {
    return axios.get('/atendimento/convenios/lista')
  },
  loadComboSituacaoAtendimento() {
    return axios.get('/atendimento/situacao_atendimentos/lista')
  },
  loadComboConselhos() {
    return axios.get('/atendimento/conselhos/lista')
  },
  loadComboEspecialidadesByIdTipoAtendimento(id) {
    return axios.get(`atendimento/especialidades/tipo/${id}`)
  },
  loadComboProfissionalByIdEspecialidade(id) {
    return axios.get(`/atendimento/profissionais/especialidade/${id}`)
  },
  loadUf() {
    return apiIbge.loadUf()
  },
  loadMunicipiosPorUf(uf) {
    return apiIbge.loadMunicipiosPorUf(uf)
  },
  loadComboProcedimentos() {
    return axios.get('/atendimento/procedimentos/lista')
  },
  loadComboSituacao() {
    return axios.get('/atendimento/situacao_atendimentos/lista')
  },
  loadComboProtocolo() {
    return axios.get('/core/protocolos/lista')
  },
  loadComboItem(id) {
    return axios.get(`core/itens/lista/${id}`)
  },
  loadComboStatusOs() {
    return axios.get('core/status_os/lista/')
  },
  loadComboGrupos() {
    return axios.get(
      '/atendimento/grupo_procedimento?page=0&size=1000&sort=nome,desc'
    )
  },
  loadComboRoles() {
    return axios.get('/core/roles?page=0&size=1000&sort=authority')
  },
  loadComboPermissions() {
    return axios.get('/perfil/lista_permissoes')
  },
  loadComboPerfils() {
    return axios.get('/perfil/?page=0&size=5000&sort=Nome,asc')
  },
  loadComboDoencasCid() {
    return axios.get('/profissional/doencas_cid/lista')
  },
  loadComboEspecialidadesExames(id) {
    return axios.get(`/profissional/procedimento/exame/especialidade/${id}`)
  },
  loadComboPerfilProfissional() {
    return axios.get('/perfil/lista_perfil_profissional')
  },
  loadComboUnidadeAtendimentoProfissional() {
    return axios.get('/profissional/unidade_atendimentos/lista')
  },
  loadComboEspecialidadesProfissional() {
    return axios.get('/profissional/especialidade')
  }
}
